<template>
  <b-modal
    ref="refModalFol"
    centered
    hide-footer
    size="lg"
    title="ข้อมูลเซลล์"
    @hidden="$emit('update:is-popup-fol-active', false)"
  >
    <div class="mb-1">
      <b-row>
        <b-col
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-input-group>
            <b-form-input
              v-model="inputSearch"
              placeholder="ค้นหาข้อมูล..."
            />
          </b-input-group>
        </b-col>
        <b-col>
          <div class="d-flex align-items-center justify-content-end">
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :text="`${perPage} รายการ / หน้า`"
              variant="outline-primary"
            >
              <b-dropdown-item
                v-for="option in perPageOptions"
                :key="option"
                @click="perPage=option"
              >
                {{ option }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="12">
          <b-overlay
            :show="overlayLoadDataTable"
            rounded
            opacity="0.6"
            spinner-variant="primary"
          >
            <b-table-simple
              caption-top
              bordered
              class="position-relative mb-1"
            >
              <b-thead head-variant="light">
                <b-tr>
                  <b-th
                    style="width: 35%;"
                  >
                    รหัสเซลล์
                  </b-th>
                  <b-th>
                    ชื่อเซลล์
                  </b-th>
                  <b-th
                    class="text-center"
                    style="width: 20%;"
                  >
                    เครื่องมือ
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-if="listFilter.length">
                  <b-tr
                    v-for="(item, index) in paginateArray(
                      listFilter,
                      perPage,
                      currentPage
                    )"
                    :key="index"
                  >
                    <b-td>
                      {{ item.acccod }}
                    </b-td>
                    <b-td>
                      {{ item.account_name }}
                    </b-td>
                    <b-td class="text-center">
                      <b-button
                        variant="gradient-primary"
                        class="btn-icon rounded-circle"
                        @click="fncSelectItem(item)"
                      >
                        <feather-icon icon="PlusIcon" />
                      </b-button>
                    </b-td>
                  </b-tr>
                </template>
                <template v-else>
                  <b-td
                    colspan="3"
                    class="text-center"
                  >
                    <p style="margin-top:5px; margin-bottom:5px;">
                      ไม่พบรายการที่ตรงกัน
                    </p>
                  </b-td>
                </template>
              </b-tbody>
            </b-table-simple>
          </b-overlay>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ DataMeta.from }} - {{ DataMeta.to }}   ทั้งหมด {{ DataMeta.of }} รายการ</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalData"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-modal>
</template>

<script>
import {
  BRow, BCol, BInputGroup, BFormInput, BDropdown, BDropdownItem, BOverlay, BTableSimple, BThead, BTr, BTh, BTbody, BTd, BPagination, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store/index'

export default {
  components: {
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BPagination,
    BButton,
  },

  directives: {
    Ripple,
  },

  props: {
    tempSearchfol: {
      type: String,
      default: null,
    },

    isPopupFolActive: {
      type: Boolean,
      default: null,
    },
  },

  setup(props, { emit }) {
    const APP_STORE_MODULE_NAME = 'appInquiryReceive'

    // ประกาศตัวแปร Ref
    const refModalFol = ref(null)

    // ประกาศตัวแปร Textbox
    const inputSearch = ref(null)

    // ประกาศตัวแปรสำหรับเก็บค่า
    const perPage = ref(10)
    const perPageOptions = [10, 25, 50, 100]
    const dataTable = ref([])
    const currentPage = ref(1)
    const totalData = ref(0)
    const itemLocalTo = ref(0)
    const itemItemCount = ref(0)
    const DataMeta = computed(() => {
      const DataMetaFormat = {
        from: itemItemCount.value,
        to: itemLocalTo.value,
        of: totalData.value,
      }

      return DataMetaFormat
    })

    // ประกาศตัวแปรแสดงสถานะ Loading
    const overlayLoadDataTable = ref(false)

    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */
    /* ฟังก์ชั่นปิด Popup */
    const fncHideModal = () => {
      refModalFol.value.hide()
    }

    /* ฟังก์ชั่นยิง API เพื่อเอาข้อมูลมาแสดงใน Table */
    const fncFetchDataTable = () => {
      overlayLoadDataTable.value = true
      const payload = {
        action: 'LIST',
        events: 'GENERAL',
        function: 'GET',
        username: null,
        access_time: null,
        cpncod_in: '0001',
        cpnbrncod_in: '0001',
        accbustyp_in: '4020',
        accdeawth_in: 'FOL',
        acccod_in: null,
      }

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_GET_ACCOUNT_MASTER`, payload)
        .then(response => {
          dataTable.value = response.data.responseData
          totalData.value = response.data.responseData.length

          itemLocalTo.value = totalData.value - (currentPage.value - 1) * perPage.value
          itemItemCount.value = totalData.value - (currentPage.value - 1) * perPage.value - totalData.value + 1

          overlayLoadDataTable.value = false // ปิดการแสดงสถานะ Loading
        })
    }

    /* ฟังก์ชั่นเมื่อมีการคลิกเลือกรายการ */
    const fncSelectItem = data => {
      emit('select-item', data)
      fncHideModal()
    }

    /* ฟังก์ชั่น เคลียร์ข้อมูลกลับสู่ Default */
    const fncInitialValue = () => {
      dataTable.value = []
      perPage.value = 10
      currentPage.value = 1
      inputSearch.value = null
      overlayLoadDataTable.value = false
    }
    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */

    /* ========== ส่วนการทำงานแสดงผล UI ========== */
    /* คำสั่งแบ่งหน้า */
    const paginateArray = (array, getPerPage, page) => array.slice((page - 1) * getPerPage, page * getPerPage)

    const listFilter = computed(() => {
      const text = inputSearch.value ? inputSearch.value.toLowerCase() : ''
      let getListFilter = []

      getListFilter = dataTable.value.filter(
        item => item.acccod.toLowerCase().indexOf(text) > -1 || item.account_name.toLowerCase().indexOf(text) > -1,
      )

      totalData.value = getListFilter.length

      return getListFilter
    })
    /* ========== ส่วนการทำงานแสดงผล UI ========== */

    watch(
      () => props.isPopupFolActive,
      (first, second) => {
        if (first) {
          /* เมื่อมีการเปิด Popup */
          fncFetchDataTable()

          if (props.tempSearchFol) inputSearch.value = props.tempSearchFol
          else inputSearch.value = null

          refModalFol.value.show()
        } else if (second) {
          /* เมื่อมีการปิด Popup */
          fncHideModal()
          fncInitialValue()
        }
      },
    )

    return {
      // ประกาศตัวแปร Ref
      refModalFol,

      // ประกาศตัวแปร Textbox
      inputSearch,

      // ประกาศตัวแปรสำหรับเก็บค่า
      perPage,
      perPageOptions,
      dataTable,
      currentPage,
      totalData,
      itemLocalTo,
      itemItemCount,
      DataMeta,

      // ประกาศตัวแปรแสดงสถานะ Loading
      overlayLoadDataTable,

      // ส่วนโค้ดการทำงานฟังก์ชั่น
      fncHideModal,
      fncSelectItem,

      // ส่วนการทำงานแสดงผล UI
      paginateArray,
      listFilter,
    }
  },
}
</script>

<style>

</style>
