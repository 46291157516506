import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    SP_CONFIG(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/Master/SearchConfig', { requestData: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    SP_GET_ACCOUNT_MASTER(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/Master/SearchGetAccountMaster', { requestData: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    SP_RT_INQ_RECEIVE(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/InquiryReceive/InqReceive', { requestData: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },

}
