<template>
  <div>
    <inquiry-receive-popup-branch
      :temp-search-branch="tempSearchBranch"
      :is-popup-branch-active.sync="isPopupBranchActive"
      @select-item="fncSelectBranch"
    />
    <inquiry-receive-popup-sales
      :temp-search-sales="tempSearchSales"
      :is-popup-sales-active.sync="isPopupSalesActive"
      @select-item="fncSelectSales"
    />
    <inquiry-receive-popup-fol
      :temp-search-fol="tempSearchFol"
      :is-popup-fol-active.sync="isPopupFolActive"
      @select-item="fncSelectFol"
    />
    <b-modal
      ref="refModalDatePickerDateStr"
      centered
      title="วันที่ระหว่าง"
      hide-footer
      size="sm"
      modal-class="modalDatePicker"
    >
      <v-date-picker
        v-model="DateStr"
        class="mt-1"
        locale="th"
        :min="setMinDatePickerStart"
        :max="setMaxDatePickerStart"
        @input="refModalDatePickerDateStr.hide()"
      />
    </b-modal>
    <b-modal
      ref="refModalDatePickerDateEnd"
      centered
      title="ถึง"
      hide-footer
      size="sm"
      modal-class="modalDatePicker"
    >
      <v-date-picker
        v-model="DateEnd"
        class="mt-1"
        locale="th"
        :min="setMinDatePickerEnd"
        :max="setMaxDatePickerEnd"
        @input="refModalDatePickerDateEnd.hide()"
      />
    </b-modal>
    <b-card
      title="รายการรับชำระ"
    >
      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="branchCode"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                พอร์ต
              </div>
            </template>
            <b-input-group v-show="!branchCode">
              <b-form-input
                id="branchCode"
                v-model="inputSearchBranch"
                placeholder="ค้นหาพอร์ต"
                trim
                @keydown.enter.native="
                  fncSearchBranch(inputSearchBranch)
                "
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncOpenPopupBranch()"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-show="branchCode">
              <b-form-input
                id="branchCode"
                :value="branchName"
                trim
                disabled
              />
              <b-input-group-append v-if="!isDefaultBranchFromLogin">
                <b-button
                  variant="primary"
                  @click="fncCancelBranch()"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row :class="isLocalServer ? 'delete-margin' : ''">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="เซลล์"
            label-for="salesCode"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                เซลล์
              </div>
            </template>
            <b-input-group v-show="!salesCode">
              <b-form-input
                id="salesCode"
                v-model="inputSearchSales"
                placeholder="ค้นหาเซลล์"
                trim
                @keydown.enter.native="
                  fncSearchSales(inputSearchSales)
                "
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncOpenPopupSales()"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-show="salesCode">
              <b-form-input
                id="salesCode"
                :value="salesName"
                trim
                disabled
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncCancelSales"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row :class="isLocalServer ? 'delete-margin' : ''">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="ผู้ตรวจ"
            label-for="folCode"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ผู้ตรวจ
              </div>
            </template>
            <b-input-group v-show="!folCode">
              <b-form-input
                id="folCode"
                v-model="inputSearchFol"
                placeholder="ค้นหาผู้ตรวจ"
                trim
                @keydown.enter.native="
                  fncSearchFol(inputSearchFol)
                "
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncOpenPopupFol()"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-show="folCode">
              <b-form-input
                id="folCode"
                :value="folName"
                trim
                disabled
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncCancelFol"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row :class="isLocalServer ? 'delete-margin' : ''">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="ทะเบียน"
            label-for="regNum"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ทะเบียน
              </div>
            </template>
            <b-form-input
              id="regNum"
              v-model="InputregNum"
              placeholder="ทะเบียน"
              trim
              autocomplete="off"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row :class="isLocalServer ? 'delete-margin' : ''">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for=""
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                วันที่ระหว่าง
              </div>
            </template>
            <div class="form-group">
              <div class="input-group">
                <b-form-input
                  id="inputDateStr"
                  :value="resolveFormatDate(DateStr)"
                  trim
                  readonly
                  placeholder="วันที่เริ่มต้น"
                  @click="fncShowDatepickerDateStr()"
                />
                <div class="input-group-prepend input-group-append">
                  <span class="input-group-text">ถึง</span>
                </div>
                <b-form-input
                  id="inputDateEnd"
                  :value="resolveFormatDate(DateEnd)"
                  trim
                  readonly
                  placeholder="วันที่สิ้นสุด"
                  @click="fncShowDatepickerDateEnd()"
                />
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- dataHeadResult: {{ dataHeadResult }}<br>
      <hr>
      dataResult: {{ dataResult }} -->
      <b-row :class="isLocalServer ? 'delete-margin' : ''">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for=""
            label-cols-md="3"
            label-cols-sm="12"
          >
            <b-overlay
              :show="overlayLoadDataTable"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-button
                variant="primary"
                @click="fncSubmit()"
              >
                ค้นหาข้อมูล
              </b-button>
            </b-overlay>
            <b-button
              class="ml-1"
              variant="outline-secondary"
              @click="fncResetForm()"
            >
              ยกเลิก
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="dataHeadResult.length"
        :class="isLocalServer ? 'delete-margin' : ''"
      >
        <b-col>
          <b-overlay
            :show="overlayLoadDataTable"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
          >
            <b-table-simple
              caption-top
              bordered
              responsive
              class="position-relative mb-1"
              small
              sticky-header
            >
              <b-thead head-variant="light">
                <b-tr>
                  <b-th
                    v-for="(item, index) in numberHeaderText"
                    :key="index"
                    class="text-center"
                    :style="`${index === 1 ? 'min-width: 200px;': ''}`"
                  >
                    {{ dataHeadResult[0][`text_${String(item).padStart(2, '0')}`] }}
                  </b-th>
                  <b-th
                    v-for="(item, index) in numberHeaderTextAmt"
                    :key="`${index}${item}`"
                    class="text-center"
                  >
                    {{ dataHeadResult[0][`text_amt${String(item).padStart(2, '0')}`] }}
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-if="dataResult.length">
                  <b-tr
                    v-for="(item, index) in paginateArray(
                      dataResult,
                      perPage,
                      currentPage
                    )"
                    :key="index"
                  >
                    <b-td class="text-center">
                      {{ (Number(index) + 1) + (perPage * (currentPage - 1)) }}
                    </b-td>
                    <b-td>
                      {{ item.customer_name }}
                    </b-td>
                    <b-td>
                      {{ item.regnum }}
                    </b-td>
                    <b-td
                      v-for="(itemAmt, indexAmt) in numberHeaderTextAmt"
                      :key="`${indexAmt}${itemAmt}`"
                      class="text-center"
                    >
                      {{ item[`amt${String(itemAmt).padStart(2, '0')}`].toLocaleString() }}
                    </b-td>
                  </b-tr>
                </template>
                <template v-else>
                  <b-tr>
                    <b-td
                      class="text-center"
                      colspan="36"
                    >
                      ไม่พบข้อมูลที่ค้นหา
                    </b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </b-overlay>
        </b-col>
      </b-row>

      <b-row v-if="dataResult.length">
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ DataMeta.from }} - {{ DataMeta.to }}   ทั้งหมด {{ DataMeta.of }} รายการ</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="dataResult.length"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label-for=""
          >
            <div style="display: inline;">
              <b-button
                variant="primary"
                @click="fncClickExportExcel"
              >
                ดาวน์โหลด Excel
              </b-button>
            </div>

          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BInputGroup, BFormInput, BInputGroupAppend, BButton, BOverlay, BTableSimple, BThead, BTr, BTh, BTbody, BTd, BPagination,
} from 'bootstrap-vue'
import { ref, computed } from '@vue/composition-api'
import store from '@/store'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import XLSX from 'xlsx' // import xlsx
import {
  VDatePicker,
} from 'vuetify/lib'
import InquiryReceivePopupBranch from './Inquiry-receive-popup/InquiryReceivePopupBranch.vue'
import InquiryReceivePopupSales from './Inquiry-receive-popup/InquiryReceivePopupSales.vue'
import InquiryReceivePopupFol from './Inquiry-receive-popup/InquiryReceivePopupFol.vue'
import InquiryReceiveStoreModule from './InquiryReceiveStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BOverlay,
    InquiryReceivePopupBranch,
    InquiryReceivePopupSales,
    InquiryReceivePopupFol,
    VDatePicker,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BPagination,
  },

  setup() {
    const isLocalServer = computed(() => {
      const getServerName = window.location.host.includes(':') ? window.location.host.split(':')[0] : window.location.host
      return getServerName === 'localhost'
    })

    const APP_STORE_MODULE_NAME = 'appInquiryReceive'
    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, InquiryReceiveStoreModule)

    // ประกาศตัวแปร Textbox
    const inputSearchBranch = ref(null)
    const InputchassisNum = ref(null)
    const InputCusName = ref(null)
    const InputregNum = ref(null)

    // ประกาศตัวแปรสำหรับเก็บค่า
    const username = ref(null)
    const cpncod = ref(null)
    const branchCode = ref(null)
    const branchName = ref(null)
    const chassisNum = ref(null)
    const tempSearchBranch = ref(null)
    const tempSearchRegNum = ref(null)
    const dataHeadResult = ref([])
    const dataResult = ref([])
    const isDefaultBranchFromLogin = ref(false)
    const salesCode = ref(null)
    const salesName = ref(null)
    const tempSearchSales = ref(null)
    const inputSearchSales = ref(null)

    const folCode = ref(null)
    const folName = ref(null)
    const tempSearchFol = ref(null)
    const inputSearchFol = ref(null)
    const regNum = ref(null)
    const DateStr = ref(null)
    const DateEnd = ref(null)

    const numberHeaderText = 3
    const numberHeaderTextAmt = 33

    const perPage = ref(25)
    const currentPage = ref(1)
    const itemItemCount = computed(() => Number(perPage.value * (currentPage.value - 1)) + 1)
    const itemLocalTo = computed(() => Number(itemItemCount.value) + Number(perPage.value) - 1)
    // 100/25 , 4 ,1 25 ,2 25*2,
    const DataMeta = computed(() => {
      const DataMetaFormat = {
        from: itemItemCount.value,
        to: itemLocalTo.value,
        of: dataResult.value.length,
      }

      return DataMetaFormat
    })

    // ประกาศตัวแปร Active / Inactive
    const isPopupBranchActive = ref(false)
    const isPopupSalesActive = ref(null)
    const isPopupFolActive = ref(null)

    // ประกาศตัวแปร Ref
    const refModalDatePickerDateStr = ref(null)
    const refModalDatePickerDateEnd = ref(null)

    /* ฟังก์ชั่นแสดง DatePicker วันที่ข้อมูล */
    const fncShowDatepickerDateStr = () => {
      refModalDatePickerDateStr.value.show()
    }

    /* ฟังก์ชั่นแสดง DatePicker วันที่ข้อมูล */
    const fncShowDatepickerDateEnd = () => {
      refModalDatePickerDateEnd.value.show()
    }

    // โค้ดกำหนดวันที่เริ่มต้น / สิ้นสุด
    // eslint-disable-next-line arrow-body-style
    const setMinDatePickerStart = computed(() => {
      if (!DateEnd.value) return null
      return moment(DateEnd.value).subtract(30, 'days').format('YYYY-MM-DD')
    })

    const setMaxDatePickerStart = computed(() => {
      if (!DateEnd.value) return null
      return moment(DateEnd.value).format('YYYY-MM-DD')
    })

    const setMinDatePickerEnd = computed(() => {
      if (!DateStr.value) return null
      return moment(DateStr.value).format('YYYY-MM-DD')
    })

    const setMaxDatePickerEnd = computed(() => {
      if (!DateStr.value) return null
      return moment(DateStr.value).add(30, 'days').format('YYYY-MM-DD')
    })

    /* ฟังก์ชั่นเปิด Popup พอร์ต */
    const fncOpenPopupBranch = search => {
      if (search) tempSearchBranch.value = search
      else tempSearchBranch.value = null

      isPopupBranchActive.value = true
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกพอร์ตจาก Popup */
    const fncSelectBranch = data => {
      branchCode.value = data.tabkeytwo
      branchName.value = data.tabdsctha
    }

    /* ฟังก์ชั่นยกเลิกพอร์ต */
    const fncCancelBranch = () => {
      branchCode.value = null
      branchName.value = null
      inputSearchBranch.value = null
    }

    /* ฟังก์ชั่นค้นหาพอร์ต */
    const fncSearchBranch = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: '',
          function: 'GET',
          recactcod: null,
          recstscod: null,
          usrcod: null,
          tabkeyone: 'CPNBRNCOD',
          tabkeytwo: val,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_CONFIG`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.tabdsctha.toLowerCase().indexOf(val) > -1 || item.tabkeytwo.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              branchCode.value = getSearchResult[0].tabkeytwo
              branchName.value = getSearchResult[0].tabdsctha
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupBranch(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นเปิด Popup เซลล์ */
    const fncOpenPopupSales = search => {
      if (search) tempSearchSales.value = search
      else tempSearchSales.value = null

      isPopupSalesActive.value = true
    }

    /* ฟังก์ชั่นค้นหาเซลล์ */
    const fncSearchSales = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: 'GENERAL',
          function: 'GET',
          username: null,
          access_time: null,
          cpncod_in: null,
          cpnbrncod_in: null,
          accbustyp_in: null,
          accdeawth_in: 'MKT',
          acccod_in: null,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_GET_ACCOUNT_MASTER`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.acccod.toLowerCase().indexOf(val) > -1 || item.account_name.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              salesCode.value = getSearchResult[0].acccod
              salesName.value = getSearchResult[0].account_name
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupSales(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกเซลล์นจาก Popup */
    const fncSelectSales = data => {
      salesCode.value = data.acccod
      salesName.value = data.account_name
    }

    /* ฟังก์ชั่นยกเลิกเซลล์ */
    const fncCancelSales = () => {
      salesCode.value = null
      salesName.value = null
      inputSearchSales.value = null
    }

    /* ฟังก์ชั่นเปิด Popup fol */
    const fncOpenPopupFol = search => {
      if (search) tempSearchFol.value = search
      else tempSearchFol.value = null

      isPopupFolActive.value = true
    }

    /* ฟังก์ชั่นค้นหาเซลล์ */
    const fncSearchFol = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: 'GENERAL',
          function: 'GET',
          username: null,
          access_time: null,
          cpncod_in: '0001',
          cpnbrncod_in: '0001',
          accbustyp_in: '4020',
          accdeawth_in: 'FOL',
          acccod_in: null,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_GET_ACCOUNT_MASTER`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.acccod.toLowerCase().indexOf(val) > -1 || item.account_name.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              folCode.value = getSearchResult[0].acccod
              folName.value = getSearchResult[0].account_name
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupFol(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกเซลล์นจาก Popup */
    const fncSelectFol = data => {
      folCode.value = data.acccod
      folName.value = data.account_name
    }

    /* ฟังก์ชั่นยกเลิกเซลล์ */
    const fncCancelFol = () => {
      folCode.value = null
      folName.value = null
      inputSearchFol.value = null
    }

    // ประกาศตัวแปรแสดงสถานะ Loading
    const overlayLoadDataTable = ref(false)

    const fncSubmit = () => {
      currentPage.value = 1 // reset กลับมาหน้าแรก เมื่อกดค้นหาใหม่

      overlayLoadDataTable.value = true
      const accesstime = new Date()
      const payloadHead = {
        action: 'INQUIRY',
        events: 'HEAD',
        function: 'SUBMIT',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        ACCESS_TIME: moment(accesstime).format('YYYY-MM-DD'),
        CPNCOD_IN: null,
        CPNBRNCOD_IN: null,
        REGNUM_IN: null,
        DATE_STR_IN: DateStr.value,
        DATE_END_IN: DateEnd.value,
        FOLLOWER_IN: folCode.value,
      }

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_INQ_RECEIVE`, payloadHead)
        .then(response => {
          // console.log('SP_RT_INQ_RECEIVE', response.data)
          dataHeadResult.value = response.data.responseData
        })

      const payloadDeatail = {
        action: 'INQUIRY',
        events: 'RECEIVE',
        function: 'SUBMIT',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        ACCESS_TIME: moment(accesstime).format('YYYY-MM-DD'),
        CPNCOD_IN: null,
        CPNBRNCOD_IN: branchCode.value,
        REGNUM_IN: InputregNum.value,
        DATE_STR_IN: DateStr.value,
        DATE_END_IN: DateEnd.value,
        SALEMAN_IN: salesCode.value,
        FOLLOWER_IN: folCode.value,
      }

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_INQ_RECEIVE`, payloadDeatail)
        .then(response => {
          // console.log('SP_RT_INQ_RECEIVE', response.data)
          dataResult.value = response.data.responseData

          overlayLoadDataTable.value = false // ปิดการแสดงสถานะ Loading
        })
        .catch(() => {
          overlayLoadDataTable.value = false
        })
    }

    /* ฟังก์ชั่นเมื่อกดปุ่มยกเลิก จะทำการ reset ข้อมูล */
    const fncResetForm = () => {
      branchCode.value = null
      InputregNum.value = null
      salesName.value = null
      folName.value = null
      DateStr.value = null
      DateEnd.value = null
    }

    /* ฟังก์ชั่น Export Excel */
    const fncClickExportExcel = () => {
      const setJsonValue = []

      // <b-th
      //   v-for="(item, index) in numberHeaderText"
      //   :key="index"
      //   class="text-center"
      //   :style="`${index === 1 ? 'min-width: 200px;': ''}`"
      // >
      //   {{ dataHeadResult[0][`text_${String(item).padStart(2, '0')}`] }}
      // </b-th>
      // <b-th
      //   v-for="(item, index) in numberHeaderTextAmt"
      //   :key="`${index}${item}`"
      //   class="text-center"
      // >
      //   {{ dataHeadResult[0][`text_amt${String(item).padStart(2, '0')}`] }}
      // </b-th>

      Object.keys(dataResult.value).forEach(key => {
        // Object.keys(numberHeaderText).forEach(keyHeadText => {

        // })

        const item = dataResult.value[key]
        // const setData = {}

        const setData = {
          [dataHeadResult.value[0].text_01]: Number(key) + 1,
          [dataHeadResult.value[0].text_02]: item.customer_name,
          [dataHeadResult.value[0].text_03]: item.regnum,
        }

        // const setDataAmt = {}
        for (let i = 0; i <= numberHeaderTextAmt; i += 1) {
          let setHeader = dataHeadResult.value[0][`text_amt${String(i).padStart(2, '0')}`]
          // eslint-disable-next-line no-restricted-globals
          if (!isNaN(setHeader)) setHeader = `วันที่ ${setHeader}`

          if (dataHeadResult.value[0][`text_amt${String(i).padStart(2, '0')}`]) setData[setHeader] = item[`amt${String(i).padStart(2, '0')}`]
        }

        // const setDataMerge = JSON.stringify(setData) + JSON.stringify(setDataAmt)
        // console.log('setDataMerge', JSON.stringify([setData, setDataAmt]))
        // eslint-disable-next-line prefer-object-spread
        // const setDataStringify = JSON.stringify(setData)
        // const setDataStringify2 = JSON.stringify(setDataAmt)
        // console.log('setDataMerge', setDataStringify.replace(/}/g, ''))
        // console.log('setDataMerge2', setDataStringify2.replace(/{/g, ','))
        // const mergerStringify = setDataStringify.replace(/}/g, '') + setDataStringify2.replace(/{/g, ',')
        // console.log('mergerStringify', JSON.parse(mergerStringify))

        setJsonValue[key] = setData
      })

      console.log('fncClickExportExcel', setJsonValue[0])

      // เปลี่ยนชื่อ Column ตามประเภท Report
      const dataWS = XLSX.utils.json_to_sheet(setJsonValue)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, dataWS)
      XLSX.writeFile(wb, `รายงานอายุหนี้_${moment(new Date()).format('YYYY-MM-DD')}.xlsx`)
    }

    /* ========== ส่วนการทำงานแสดงผล UI ========== */
    /* กำหนดปีเป็น พ.ศ. */
    const toBuddhistYear = (val, format) => {
      const christianYear = val.format('YYYY')
      // eslint-disable-next-line radix
      const buddhishYear = (parseInt(christianYear) + 543).toString()
      return val
        .format(
          format
            .replace('YYYY', buddhishYear)
            .replace('YY', buddhishYear.substring(2, 4)),
        )
        .replace(christianYear, buddhishYear)
    }

    /* กำหนดรูปแบบ ว/ด/ป */
    const resolveFormatDate = val => (val
      ? toBuddhistYear(moment(val), 'DD/MM/YYYY')
      : null)

    const paginateArray = (array, getPerPage, page) => array.slice((page - 1) * getPerPage, page * getPerPage)
    /* ========== ส่วนการทำงานแสดงผล UI ========== */

    return {
      isLocalServer,

      setMinDatePickerStart,
      setMaxDatePickerStart,
      setMinDatePickerEnd,
      setMaxDatePickerEnd,

      // ประกาศตัวแปร Ref
      refModalDatePickerDateStr,
      fncShowDatepickerDateStr,
      refModalDatePickerDateEnd,
      fncShowDatepickerDateEnd,

      // ประกาศตัวแปร Textbox
      inputSearchBranch,
      InputchassisNum,
      InputCusName,
      inputSearchSales,
      inputSearchFol,
      InputregNum,

      // ประกาศตัวแปรสำหรับเก็บค่า
      username,
      cpncod,
      branchCode,
      branchName,
      chassisNum,
      tempSearchBranch,
      tempSearchRegNum,
      dataHeadResult,
      dataResult,
      isDefaultBranchFromLogin,
      salesCode,
      salesName,
      tempSearchSales,
      folCode,
      folName,
      tempSearchFol,
      regNum,
      DateStr,
      DateEnd,

      numberHeaderText,
      numberHeaderTextAmt,
      perPage,
      currentPage,
      DataMeta,

      // ส่วนการทำงานแสดงผล UI
      resolveFormatDate,
      paginateArray,

      // ประกาศตัวแปรแสดงสถานะ Loading
      overlayLoadDataTable,

      // ส่วนโค้ดการทำงานฟังก์ชั่น
      fncOpenPopupBranch,
      fncSearchBranch,
      fncCancelBranch,
      fncSelectBranch,
      fncSearchSales,
      fncOpenPopupSales,
      fncSelectSales,
      fncCancelSales,
      fncSearchFol,
      fncOpenPopupFol,
      fncSelectFol,
      fncCancelFol,
      fncSubmit,
      fncResetForm,
      fncClickExportExcel,

      // ประกาศตัวแปร Active / Inactive
      isPopupBranchActive,
      isPopupSalesActive,
      isPopupFolActive,
    }
  },
}
</script>

<style>
.v-date-picker-title {
    color: black;
}

.v-picker__title.primary {
  margin-top: -80px;
}

.v-date-picker-table.v-date-picker-table--date.theme--light {
  margin-bottom: -30px;
}

.v-picker.v-card.v-picker--date.theme--light {
  margin-left: 0px;
}

button.v-btn.v-btn--active.v-btn--rounded.theme--light.accent {
  color: blue;
}

button.v-btn.v-size--default.v-date-picker-table__current.v-btn--active.v-btn--text.theme--light.accent {
  color: blue;
}

button.v-btn.v-size--default.v-btn--active.theme--light.accent {
  color: blue;
}

.v-picker__title.primary {
  display: none;
}

.modalDatePicker .modal-body {
  padding: 0px;
}

.modalDatePicker .modal-content {
  width: 280px;
}

.v-picker__body.theme--light {
  width: 280px !important;
}

body.modal-open {
  height: 100vh;
  overflow-y: hidden;
}

</style>

<style lang="scss" scoped>
.delete-margin {
  margin-top: -40px;
}

.table thead th, .table tfoot th {
    vertical-align: middle;
    text-transform: uppercase;
    font-size: 0.9rem;
    letter-spacing: 0.5px;
    height: 40px;
}

.table tbody td {
    vertical-align: middle;
    letter-spacing: 0.5px;
    height: 50px;
}

.b-table-sticky-header {
    overflow-y: auto;
    max-height: 500px !important;
}
</style>
